<template>
	<div class="crm-profile crm-profile-nav" :class="`card-bg-${patient.groupTag}`">
		<div class="crm-profile-header">
			<div class="crm-profile-header__left">
				<p><strong>姓名：</strong>{{ patient.name }} <SexIcon :sex="patient.sex" /></p>
				<p><strong>手机号：</strong>{{ patient.cellphone | arrPhone }}</p>
				<p><strong>支付方式：</strong>{{ patient.payType | convertDict(pay_type) }}</p>
				<p><strong>住院次数：</strong>{{ patient.inHospitalTotal }} 次</p>
			</div>
			<div class="crm-profile-header__right">
				<StatusIcon :status="patient.status" />
			</div>
		</div>
		<div class="crm-profile-body" :class="`card-bg-${patient.groupTag}`">
			<div class="crm-profile-body__top">
				<van-grid :border="false" clickable :column-num="5">
          <van-grid-item icon="home-o" text="病史"  @click="jumpPage('/crm/history/' + patient.id)" >
            <template slot="icon">
              <span class="nav-card history"></span>
            </template>
          </van-grid-item>
          <van-grid-item icon="search" text="入院申请" @click="hospitalAdmissionApplication">
            <template slot="icon">
              <span class="nav-card apply"></span>
            </template>
          </van-grid-item>
          <van-grid-item icon="search" text="添加备注"  @click="jumpPage('/crm/create-remarks', patient)">
            <template slot="icon">
              <span class="nav-card remarks"></span>
            </template>
          </van-grid-item>
          <van-grid-item icon="search" text="修改信息"  @click="jumpPage('/crm/edit-patient', patient)">
            <template slot="icon">
              <span class="nav-card change"></span>
            </template>
          </van-grid-item>
          <van-grid-item icon="home-o" text="联系人"  @click="jumpPage('/crm/contact/' + patient.id)" >
            <template slot="icon">
              <span class="nav-card contact"></span>
            </template>
          </van-grid-item>
        </van-grid>
			</div>
			<!-- tab -->
			<van-tabs @click="onClick">
				<van-tab
					>
					<template #title> 入院记录（{{applyTotal}}） </template>

					<div v-if="applyList.length == 0" class="pb-16">
						<van-empty description="暂无任何入院申请"> </van-empty>
					</div>
					<div class="crm-card pb-0 mb-16" :class="item.is_show ? 'expend' : 'collapse'" v-for="(item, index) in applyList" :key="index">
						<div class="crm-card-title" style="    flex-direction: row-reverse;">
							<van-tag class="" plain size="small" round :color="item.status | statusTag">{{
											item.status_dictText
										}}</van-tag>
							{{ item.applyType_dictText }}
	          </div>
						<div class="crm-card-body">
							<div v-if="item.status === 'IN-HOSPITAL'" class="crm-card-info" @click="jumpPage('/crm/detail-apply/' + item.id)">
								<p><strong>医院:</strong>{{ item.nsArea_dictText }}</p>
								<p><strong>病区/床位:</strong>{{ item.inWard||item.nsWard }} <span v-if="item.inBed">/</span>  {{ item.inBed }}</p>
								<p><strong>住院天数:</strong>{{ item.inDays }}</p>
								<p><strong>会诊医生:</strong>{{ item.consultationDoctor }}</p>
								<p><strong>入院日期:</strong>{{ item.inHosTime | formatDate("YYYY-MM-DD") }}</p>
							</div>
							<div v-if="item.status === 'WAIT-AUDIT'" class="crm-card-info" @click="jumpPage('/crm/detail-apply/' + item.id)">
								<template v-if="item.applyType === 'normal'">
									<p><strong>转诊科室：</strong>{{ item.zzDepartment === "other" ? item.zzDepartmentOther : item.zzDepartment_dictText }}</p>
								</template>
								<p><strong>会诊医生:</strong>{{ item.consultationDoctor }}</p>
								<p><strong>拟入院时间:</strong>{{item.nsInDate || item.zzPlanInDate }}</p>
								<p><strong>申请人:</strong>{{ item.createBy }} </p>
								<p><strong>申请时间:</strong>{{ item.createTime }}</p>
							</div>
							<div v-if="item.status === 'WAIT-IN'" class="crm-card-info" @click="jumpPage('/crm/detail-apply/' + item.id)">
								<p><strong>病区/床位:</strong>{{ item.inWard||item.nsWard }} <span v-if="item.inBed">/</span>  {{ item.inBed }}</p>
								<p><strong>会诊医生:</strong>{{ item.consultationDoctor }}</p>
								<p><strong>拟入院时间:</strong>{{item.nsInDate || item.zzPlanInDate }}</p>
								<p><strong>申请人:</strong>{{ item.createBy }} </p>
								<p><strong>更新时间:</strong>{{ item.updateTime }}</p>
							</div>
							<div v-if="item.status === 'OUT'" class="crm-card-info" @click="jumpPage('/crm/detail-apply/' + item.id)">
								<p><strong>病区/床位:</strong>{{ item.inWard||item.nsWard }} <span v-if="item.inBed">/</span>  {{ item.inBed }}</p>
								<p><strong>会诊医生:</strong>{{ item.consultationDoctor }}</p>
								<p><strong>出院方式:</strong>{{item.outType_dictText }}</p>
								<p><strong>住院天数:</strong>{{ item.inDays }}</p>
								<p><strong>出院时间:</strong>{{ item.outHosTime }}</p>
							</div>
						</div>
						<div v-if="item.is_show">
							<div class="crm-card-title">出院诊断</div>
							<div class="crm-card-body">
								<div class="crm-card-info well">
									{{ item.outRemarks }}
								</div>
							</div>
						</div>
						<div class="crm-card-arrow" @click="showHide(item)">
							<span class="crm-card-arrow-icon"></span>
						</div></div
				></van-tab>
				<van-tab >
					<template #title> 医疗备注（{{remarksTotal}}）</template>
					<div v-if="remarksList.length === 0" class="pb-16">
						<van-empty description="暂无任何入院申请"> </van-empty>
					</div>
					<div class="crm-card pb-0 mb-16" v-if="remarksList.length !== 0">
						<van-steps direction="vertical" :active="100000">
							<van-step v-for="(item, index) in remarksList" :key="index">
								<template v-slot:inactive-icon>
									<span class="success-dian" style="background-color: rgb(82, 196, 26)" v-if="item.emergencyLevel == '1'">普</span>
									<span class="success-dian" style="background-color: rgb(255 0 0)" v-if="item.emergencyLevel == '9'">高</span>
									<span class="success-dian" style="background-color: #409eff" v-if="item.emergencyLevel == '5'">中</span>
								</template>

								<template v-slot:active-icon>
									<van-icon name="setting-o" />
									<!-- <van-icon :name="item.icon" :class="item.classActive" /> -->
								</template>

								<div class="crm-message-content">
									<van-row type="flex" justify="space-between">
										<van-col>
											<p :class="item.status != null ? 'crm_message_through' : ''">
												<span class="age">{{ item.createBy }}</span>
												<span class="username">{{ item.createTime | formatDate("YYYY-MM-DD HH:mm") }}</span>
											</p>
										</van-col>
									</van-row>
									<p>
										<van-row style="padding-top: 2px">
											<van-col class="crm-message-text" :span="19" :class="item.status!=null?'crm_message_through':''">
												<TextOverflow   :text="`${item.remarks}`"  :maxLines="3" >
													<template v-slot:default="{ clickToggle, expanded }">
														<van-tag @click="clickToggle" class="expanded-btn" >
															{{ expanded ? "收起" : "展开" }}
														</van-tag>
													</template>
												</TextOverflow>
											</van-col>

											<!-- ceshi -->
											<!-- <van-col class="crm-message-text " :span="19"><span class="crm_message_ellipsis">{{ item.remarks }}</span><span style="color:blue;">更多</span> </van-col> -->
											<van-col class="crm-message-text crm_message_image" :span="19">
												<ImgField label1="照片信息" :options="item.images | convertImg" />
											</van-col>

											<van-col v-if="item.contact" class="crm-message-text crm_new_contact" :span="19">
												<span :class="item.status != null ? 'crm_message_through' : ''">最新联系号码：{{ item.contact }}</span>
											</van-col>
											<van-col v-if="item.exeRemarks" class="crm-message-text" :span="19" :class="item.status!=null?'crm_message_through':''">
												<TextOverflow   :text="`处理备注:${item.exeRemarks}`" :maxLines="3" >
													<template v-slot:default="{ clickToggle, expanded }">
														<van-tag @click="clickToggle" class="expanded-btn" >
															{{ expanded ? "收起" : "展开" }}
														</van-tag>
													</template>
												</TextOverflow>
											</van-col>
											<van-col class="crm-message-btn" :span="5"
												><van-tag
													@click="handleBtn(item.id)"
													v-if="item.status == null"
													class="crm-message-tag"
													plain
													size="small"
													color="#7232dd"
													round
													>处理
												</van-tag>
											</van-col>
										</van-row>
									</p>
									<template v-if="item.exeTime != null">
										<div>
											<van-row type="flex" justify="space-between">
												<van-col>
													<p>
														<span :class="item.status != null ? 'crm_message_through' : ''" class="username">{{
															item.exeTime | formatDate("YYYY-MM-DD HH:mm")
														}}</span>
														&nbsp;
														<span :class="item.status != null ? 'crm_message_through' : ''" class="sex">{{ item.exeBy }} </span>
														&nbsp;
														<van-tag
															v-if="item.status != null"
															class="crm-message-tag"
															plain
															size="small"
															color="#969696"
															round
															disabled
															>{{ item.status_dictText }}
														</van-tag>
													</p>
												</van-col>
											</van-row>
										</div>
									</template>
								</div>
							</van-step>
						</van-steps>
					</div></van-tab
				>
			</van-tabs>
			<!-- tab -->
		</div>
		<van-dialog v-model="showInfo" show-cancel-button class-name="custom-dialog" @confirm="confirm">
			<div class="rk-modal">
				<div class="rk-modal-body">
					<div class="rk-top"></div>
					<h1>请选择执行的处理状态</h1>
					<div class="rk-modal-footer">
						<van-radio-group @change="getStatus" v-model="statusType" direction="horizontal">
							<van-radio :name="item.value" v-for="item in remarks_exe_status_code" :key="item.value">{{ item.title }}</van-radio>
						</van-radio-group>
          </div>
				</div>
			</div>
		</van-dialog>
		<van-dialog v-model="reasonShow" show-cancel-button class-name="custom-dialog" @confirm="confirm">
			<div class="rk-modal">
				<div class="rk-modal-body">
					<div class="rk-top"></div>
					<h1>处理备注</h1>
					<div class="rk-modal-desc">
						<CRMTextarea
						label1="处理备注"
						:rows="2"
						:attr="{ maxlength: 1000, 'show-word-limit': true,required: true }"
						v-model="talkRemarks"
						name="talkRemarks"
						:rules="[
								{ required: true, message: '请填写处理备注' },
								,
							]"
						placeholder="请填写处理备注"
					/>
					</div>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import crmMIxins from "@/utils/crm-mixins.js";
import ImgField from "@/components/CRM/ImgField";
import SexIcon from "@/components/CRM/SexIcon";
import StatusIcon from "@/components/CRM/StatusIcon";
import CRMTextarea from "@/components/CRM/Textarea";
import TextOverflow from "./TextOverflow.vue";
import api from "@/request/api";
import { Notify, Dialog } from "vant";
import VClamp from "vue-clamp";

export default {
	mixins: [crmMIxins],
	components: { ImgField, SexIcon, StatusIcon, VClamp, TextOverflow, CRMTextarea },
	data() {
		return {
			talkRemarks: "",
			applyTotal: "",
			remarksTotal: "",
			status: 1,
			show: false,
			showInfo: false,
			reasonShow: false,
			patient: {},
			applyList: [],
			remarksList: [],
			remarksId: "",
			patientId: "",
			clamped: false,
			statusType: null,
			remarks_exe_status_code: [],
		};
	},
	mounted() {
		const id = this.$route.params.id;
		this.patientId = this.$route.params.id;
		this.getPatientDetail(id);
		this.crmInHospitalApplyInfo(id);
		this.crmRemarksInfo(id);
		this.remarks_exe_status_code = this.remarks_exe_status.filter((item) => item.value != "2" && item.value != "3");
	},
	methods: {
    hospitalAdmissionApplication () {
      if (this.patient.tag && this.patient.tag.indexOf('dispute') !== -1) {
        Dialog.confirm({
          title: '',
          message: '该患者既往住院中存在医疗纠纷记录，请与医教部、个案部沟通后提交',
          confirmButtonText: '继续申请',
          cancelButtonText: '取消'
        }).then(() => {
          api.patientApplyRecordsCreate({type: 1, patientId: this.patient.id})
          this.jumpPage(`/crm/create-apply/${this.patient.id}?patientName=${this.patient.name}`)
        })
      } else {
        this.jumpPage(`/crm/create-apply/${this.patient.id}?patientName=${this.patient.name}`)
      }
    },
		getStatus(val) {
			console.log("val", val);
			let vm = this;
			const obj = {};
			obj.status = vm.statusType;
			if (obj.status == "4") {
				vm.reasonShow = true;
			}
		},
		confirm() {
			let vm = this;
			const obj = {};
			obj.id = vm.remarksId;
			obj.exeRemarks = vm.talkRemarks;
			obj.status = vm.statusType;
			if (obj.status != "4") {
				obj.exeRemarks = "";
			}
			if (obj.status == "4" && obj.exeRemarks == "") {
				Notify({ type: "danger", message: "未输入原因" });
				vm.statusType = null;
			} else {
				api.crmProcessEdit(obj).then((res) => {
					this.hideLoading();
					if (res.success) {
						Notify({ type: "success", message: res.message });
						this.crmRemarksInfo(vm.patientId);
					} else {
						Notify({ type: "danger", message: res.message });
					}
				});
				vm.showInfo = false;
				vm.statusType = null;
			}

		},
		handleReject() {
			let vm = this;
			const obj = {};
			obj.id = vm.remarksId;
			obj.status = 1;
			api.crmProcessEdit(obj).then((res) => {
				this.hideLoading();
				if (res.success) {
					Notify({ type: "success", message: res.message });
					this.crmRemarksInfo(vm.patientId);
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
			vm.showInfo = false;
			vm.reasonShow = false;
		},
		rejectApply() {
			let vm = this;
			const obj = {};
			obj.id = vm.remarksId;
			obj.status = 2;
			api.crmProcessEdit(obj).then((res) => {
				this.hideLoading();
				if (res.success) {
					Notify({ type: "success", message: res.message });
					this.crmRemarksInfo(vm.patientId);
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
			vm.showInfo = false;
		},
		rejectCancel() {
			let vm = this;
			const obj = {};
			obj.id = vm.remarksId;
			obj.status = 3;
			api.crmProcessEdit(obj).then((res) => {
				this.hideLoading();
				if (res.success) {
					Notify({ type: "success", message: res.message });
					this.crmRemarksInfo(vm.patientId);
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
			vm.showInfo = false;
		},
		handleBtn(remarksId) {
			let vm = this;
			vm.remarksId = remarksId;
			vm.showInfo = true;
		},

		// tab
		onClick(name, title) {
			// this.$toast(title);
		},
		showHide(item) {
			item.is_show = !item.is_show;
			this.$forceUpdate();
		},
		getPatientDetail(id) {
			api.crmPatientQueryById(id).then((res) => {
				if (res.success) {
					this.patient = res.result;
				}
			});
		},
		crmInHospitalApplyInfo(patientId) {
			api.crmInHospitalApplyList({
				patientId,
				column: "create_time",
				order: "desc",
				pageNo: 1,
				pageSize: 1000,
				status: "CREATED,WAIT-AUDIT,IN-HOSPITAL,FIRST,WAIT-IN,OUT,REJECT",
			}).then((res) => {
				if (res.success) {
					this.applyList = res.result.records;
					this.applyTotal = res.result.total;
				}
			});
		},
		crmRemarksInfo(patientId) {
			api.crmRemarksList({
				patientId,
				column: "create_time",
				order: "desc",
				pageSize: 1000,
			}).then((res) => {
				if (res.success) {
					this.remarksTotal = res.result.total;
					this.remarksList = res.result.records;
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
		},
	},
};
</script>

<style lang="less">
@import url("~@/variables.less");
.expanded-btn {
	display: flex;
	font-size: 14px;
	width: 100%;
	height: 0.667rem;
	border-radius: -0.667rem;
	background-color: #170404;
	color: #d2d2e4;
	transition: all 0.3s ease;
	justify-content: center;
	align-items: center;
}
.crm_message_through {
	text-decoration: line-through;
	color: #969696;
}
.crm_message_ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
.crm_message_image {
	.img-field-box {
		flex-direction: column;
		.img-list {
			margin-left: 0px;
		}
	}
}
.crm_new_contact {
	span {
		background-color: #7232ddd4;
		border-radius: 6px;
		padding: 6px 6px;
		margin-top: 6px;
		color: #fff;
		font-size: 12px;
	}
}
.rk-modal {
	p {
		.desc;
		color: @font2;
		text-align: left;
	}
}
.rk-modal-body {
	background: @font9;
	border-radius: 20px;
	padding: 40px 0 0;
	h1 {
		.btn-font;
		color: @font1;
		padding: 36px 0 24px;
	}
	a {
		display: inline-block;
	}
}

.rk-modal-desc {
	max-height: 286px;
	padding: 0 40px;
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 28px;
}
.rk-modal-footer {
	padding: 27px 0;
	display: flex;
	padding-top: 20px;
	align-items: center;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;

	.rk-yzx {
		// width: 340px;
		padding: 0 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
		background: @color4;
		border-radius: 35px;
		color: #fff;
	}
	.rk-zx {
		padding: 0 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
		background: @color4;
		border-radius: 35px;
		color: #fff;
		background: @bg3;
	}
	.rk-sx {
		padding: 0 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
		background: @font5;
		border-radius: 35px;
		color: #fff;
	}
}
.crm-message-text {
	text-align: left;
}
.crm-message-btn {
	position: absolute;
	right: 0;
}
.crm-message-content {
	padding-left: 0;
}
.crm-card-ylbz {
	// font-weight: 600;
	// font-size: 18px;
	line-height: 1.493rem;
	color: #333333;
	text-align: left;
	padding: 0 0.533rem;
	margin-bottom: 0.853rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	// align-items: center;
	.action-title {
		display: inline-block;
		width: 70px;
	}
}
.success-dian {
	display: inline-block;
	width: 36px;
	height: 36px;
	// font-size: 5px;
	background: #07c160;
	color: #fff;
	border-radius: 50%;
	line-height: 36px;
}
.crm-profile {
	width: 100%;
	height: 100%;
	background: url("~@/assets/crm/profile-bg.png") no-repeat center top/contain;
	background-color: #fff;
	overflow: auto;
	&.card-bg-1 {
		background-color: #ffefe8;
	}
	&.card-bg-2 {
		background-color: #ecf8fe;
	}
	&.card-bg-3 {
		background-color: #f4fbf0;
	}
}
.crm-profile-nav {
	.crm-profile-body {
		padding: 20px 32px;
		.crm-profile-body__top {
			padding: 0;
		}
	}
	.crm-profile-header {
		padding-bottom: 40px;
	}
	.crm-profile-header .crm-profile-header__right {
		position: absolute;
		right: 40px;
		top: 50px;
		display: inline-block;
		width: 198px;
		height: 198px;
		border-radius: 50%;
		overflow: hidden;
		img {
			display: inline-block;
			width: 100%;
			height: auto;
		}
	}
	.crm-profile-header .crm-profile-edit {
		position: absolute;
		bottom: 36px;
		right: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 240px;
		height: 56px;
		border-radius: 30px 0 0 30px;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		font-weight: 400;
		font-size: 28px;
		line-height: 39px;
		text-align: right;
		color: #5f5aa3;
		padding-right: 18px;
		padding-left: 10px;
	}
}
.crm-profile-body {
	background: #ffffff;
	padding: 96px 32px;
	border-radius: 100px 100px 0px 0px;
	.crm-profile-body__top {
		padding: 0 32px 64px;
	}
	&.card-bg-1 {
		background-color: #ffefe8;
	}
	&.card-bg-2 {
		background-color: #ecf8fe;
	}
	&.card-bg-3 {
		background-color: #f4fbf0;
	}
}
.crm-profile-header {
	display: flex;
	flex-direction: row;
	padding: 80px;
	color: #fff;
	text-align: left;
	position: relative;
	h1 {
		font-size: 40px;
		font-weight: 600;
		margin-bottom: 24px;
	}
	p {
		font-weight: 400;
		font-size: 28px;
		line-height: 39px;
		margin-bottom: 7px;
		strong {
			display: inline-block;
			width: 200px;
			font-weight: 600;
			font-size: 32px;
			line-height: 45px;
			margin-right: 24px;
		}
	}
	.crm-profile-header__left {
		width: 100%;
	}

	.medical-remarks-edit {
		position: absolute;
		bottom: 18px;
		left: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 240px;
		height: 56px;
		border-radius: 0 30px 30px 0;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		font-weight: 400;
		font-size: 28px;
		line-height: 39px;
		text-align: right;
		color: #5f5aa3;
		padding-right: 18px;
		padding-left: 10px;
	}
	.edit-icon {
		display: inline-block;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: url("~@/assets/crm/icon/icon-edit.png") no-repeat center center/contain;
	}
}
.nav-card {
	display: inline-block;
	width: 84px;
	height: 84px;
	&.history {
		background: url("~@/assets/crm/icon/icon-xx.png") no-repeat center center / contain;
	}
	&.remarks {
		background: url("~@/assets/crm/icon/icon-remarks.png") no-repeat center center / contain;
	}
	&.change {
		background: url("~@/assets/crm/icon/icon-bs.png") no-repeat center center / contain;
	}
	&.apply {
		background: url("~@/assets/crm/icon/icon-rysq.png") no-repeat center center / contain;
	}
	&.contact {
		background: url("~@/assets/crm/icon/icon-lxr.png") no-repeat center center / contain;
	}
}
.profile-card {
	width: 100%;
	height: 218px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	&.history {
		background-image: url("~@/assets/crm/icon/icon-bs.png");
	}
	&.apply {
		background-image: url("~@/assets/crm/icon/icon-to-apply.png");
	}
}
</style>
